import { useState, useEffect } from "react";

import { ChevronDown } from "lucide-react";
import Link from "next/link";

import AccessControl from "@/components/permissions/AccessControl";
import { cn } from "@/lib/utils";

const SidebarOptionAccordion = ({
  option,
  subLinkClasses,
  currentSection,
}) => {
  const [open, setOpen] = useState(false);
  const isCurrentSection = (section) => {
    if (section.handle == currentSection) return true;
    if (!section.subMenu) return false;

    return section.subMenu.find((sub) => sub.handle == currentSection);
  };

  useEffect(() => {
    if (isCurrentSection(option)) {
      setOpen(true);
    }
  }, [currentSection]);

  return (
    <div>
      <div
        className={cn(
          "flex items-center gap-2 cursor-pointer justify-between text-md py-4 pl-6 pr-2 [&>_svg]:w-[20px] [&>_svg]:mr-3 hover:text-slate-600 hover:bg-slate-100 text-slate-400",
          open ? "text-slate-600" : "",
          isCurrentSection(option) ? "text-blue-600" : ""
        )}
        onClick={() => setOpen(!open)}
      >
        <div
          value={option.handle}
          className="flex items-center gap-2 cursor-pointer w-full border-none"
        >
          <option.icon />
          <div>{option.name}</div>
        </div>
        <ChevronDown
          size={16}
          className={cn(
            "transition-transform duration-500 ease-in-out",
            open ? "transform rotate-180" : ""
          )}
        />
      </div>

      <div
        className={cn(
          "overflow-hidden max-h-0 transition-all duration-500 ease-in-out",
          open ? "max-h-max" : ""
        )}
      >
        {option.subMenu.map((subOption, index) => (
          <AccessControl
            requiredPermissions={subOption.requiredPermissions}
            key={index}
          >
            <Link href={subOption.link}>
              <div
                className={cn(
                  "flex items-center gap-2 cursor-pointer py-2 pl-8 pr-3 border-none text-sm",
                  subOption.handle === currentSection
                    ? "text-slate-600"
                    : subLinkClasses
                )}
              >
                <subOption.icon size={18} />
                <span>{subOption.name}</span>
              </div>
            </Link>
          </AccessControl>
        ))}
      </div>
    </div>
  );
};

export default SidebarOptionAccordion;
