"use client";

import { useState, useEffect } from "react";

import { useReactiveVar } from "@apollo/client";
import { isEmpty } from "lodash";
import { useSearchParams } from "next/navigation";

import { useQuickView } from "@/hooks/useQuickView";
import { userDataVar } from "@/lib/local-state";
import { QuickViewRegistry } from "@/lib/quickview-registry";

export default function DeeplinkHandler() {
  const searchParams = useSearchParams();
  let session = useReactiveVar(userDataVar);

  const { open } = useQuickView();
  const [processedDeeplink, setProcessedDeeplink] = useState(false); 

  useEffect(() => {
    const type = searchParams.get("type");
    const encryptedId = searchParams.get("quickviewId");
    if (processedDeeplink || !type || !encryptedId || isEmpty(session)) return;

    if (type in QuickViewRegistry) {
      open(type, { id: encryptedId });
      setProcessedDeeplink(true);
    }
  }, [session]);

  return null;
}
