import { useRouter, useSearchParams } from "next/navigation";

import { quickViewStackVar } from "@/lib/local-state";
import { QuickViewRegistry } from "@/lib/quickview-registry";

export const useQuickView = () => {
  const router = useRouter();
  const searchParams = useSearchParams();

  /**
   * Updates the URL when a view has deeplinks
   * @param {string} viewType - The type of view to open
   * @param {Object} customProps - Custom properties for the view
   */
  const updateDeeplinkUrl = (viewType, customProps) => {
    const currentParams = new URLSearchParams(searchParams.toString());
    currentParams.set("type", viewType);

    if (customProps.id) {
      currentParams.set("quickviewId", customProps.id);
    } else {
      currentParams.delete("quickviewId");
    }

    router.replace(
      `${window.location.pathname}?${currentParams.toString()}`,
      {
        scroll: false,
      }
    );
  };

  /**
   * Opens a new QuickView and adds it to the stack
   * @param {string} viewType - The type of view to open (must be registered in QuickViewRegistry)
   * @param {Object} customProps - Custom properties to pass to the view
   */
  const open = (viewType, customProps = {}) => {
    if (QuickViewRegistry[viewType]) {
      const { defaultProps, hasDeeplink } = QuickViewRegistry[viewType];
      const currentStack = quickViewStackVar();

      const newStack =
        currentStack.length > 0
          ? currentStack.map((item) => ({
              ...item,
              props: {
                ...item.props,
                isVisible: false,
              },
            }))
          : [];

      const newItem = {
        type: viewType,
        props: {
          ...defaultProps,
          ...customProps,
          isVisible: true,
          removing: false,
        },
      };

      quickViewStackVar([...newStack, newItem]);

      if (hasDeeplink) {
        updateDeeplinkUrl(viewType, customProps);
      }
    }
  };

  /**
   * Replaces the last QuickView in the stack with a new one
   * @param {string} viewType - The type of view to open (must be registered in QuickViewRegistry)
   * @param {Object} customProps - Custom properties to pass to the view
   */
  const replace = (viewType, customProps = {}) => {
    if (QuickViewRegistry[viewType]) {
      const { defaultProps, hasDeeplink } = QuickViewRegistry[viewType];
      const currentStack = quickViewStackVar();
      
      if (currentStack.length === 0) {
        open(viewType, customProps);
        return;
      }

      const stackWithoutLast = currentStack.slice(0, currentStack.length - 1);
      const newItem = {
        type: viewType,
        props: {
          ...defaultProps,
          ...customProps,
          isVisible: true,
          removing: false,
        },
      };

      quickViewStackVar([...stackWithoutLast, newItem]);
      
      if (hasDeeplink) {
        updateDeeplinkUrl(viewType, customProps);
      }
    }
  };

  /**
   * Closes a QuickView at the specified index (or the last one by default)
   * @param {number} [index] - Optional index of the QuickView to close
   */
  const close = (index) => {
    const currentStack = quickViewStackVar();
    if (currentStack.length > 0) {
      const targetIndex = index ?? currentStack.length - 1;

      const newStack = currentStack.map((item, i) => ({
        ...item,
        props: {
          ...item.props,
          isVisible: i === targetIndex - 1,
          removing: i === targetIndex,
        },
      }));

      quickViewStackVar(newStack);

      const remainingStack = newStack.filter((item) => !item.props.removing);
      
      if (remainingStack.length === 0) {
        const currentParams = new URLSearchParams(searchParams.toString());
        currentParams.delete("type");
        currentParams.delete("quickviewId");
        
        router.replace(
          `${window.location.pathname}?${currentParams.toString()}`,
          {
            scroll: false,
          }
        );
      } else {
        const lastItem = remainingStack[remainingStack.length - 1];
        updateDeeplinkUrl(lastItem.type, lastItem.props);
      }
    }
  };

  return {
    open,
    replace,
    close,
    quickViewStackVar,
  };
};
