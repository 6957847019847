import { useEffect, useState } from "react";

import { useReactiveVar } from "@apollo/client";
import ReactMarkdown from "react-markdown";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { userDataVar } from "@/lib/local-state";
import {
  fetchReleaseNotes,
  hasUserSeenReleaseNotes,
  markReleaseNotesAsSeen,
  getCurrentVersion,
  getVersionChangeType,
} from "@/lib/services/releaseNotes";

const ReleaseNotesModal = ({ isOpen: forcedOpen, onClose }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [releaseNotes, setReleaseNotes] = useState("");
  const [currentVersion, setCurrentVersion] = useState("");
  const [changeType, setChangeType] = useState("");
  const userData = useReactiveVar(userDataVar);

  const loadReleaseNotes = async (shouldMarkAsSeen = false) => {
    try {
      const version = getCurrentVersion();
      const notes = await fetchReleaseNotes(version);
      
      const lastSeenVersion = localStorage.getItem('lastSeenReleaseNotes');
      const versionChangeType = getVersionChangeType(lastSeenVersion, version);
      
      setReleaseNotes(notes);
      setCurrentVersion(version);
      setChangeType(versionChangeType);
      setIsOpen(true);
      
      if (shouldMarkAsSeen) {
        markReleaseNotesAsSeen();
      }
      
      return true;
    } catch (error) {
      console.error("Error al cargar las release notes:", error);
      return false;
    }
  };

  useEffect(() => {
    if (!userData) return;

    if (!hasUserSeenReleaseNotes()) {
      const timer = setTimeout(() => {
        loadReleaseNotes(true);
      }, 1500);
      
      return () => clearTimeout(timer);
    }
  }, [userData]);

  useEffect(() => {
    if (forcedOpen) {
      loadReleaseNotes(false);
    }
  }, [forcedOpen]);

  const handleClose = () => {
    setIsOpen(false);
    if (onClose) {
      onClose();
    }
  };

  if (!releaseNotes) return null;

  const getChangeTypeLabel = () => {
    switch (changeType) {
      case 'major': return 'Major Update';
      case 'minor': return 'Minor Update';
      case 'patch': return 'Patch Update';
      default: return '';
    }
  };

  return (
    <Dialog open={isOpen || forcedOpen} onOpenChange={handleClose}>
      <DialogContent className="max-w-2xl max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>
            What&apos;s New in v{currentVersion}
            {changeType && <span className="ml-2 text-sm font-normal opacity-70">({getChangeTypeLabel()})</span>}
          </DialogTitle>
        </DialogHeader>

        <div className="prose dark:prose-invert prose-sm max-w-none py-4 markdown-content">
          <ReactMarkdown>{releaseNotes}</ReactMarkdown>
        </div>

        <DialogFooter>
          <Button onClick={handleClose}>Got it!</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ReleaseNotesModal;
