import packageJson from "../../../package.json";
const { version } = packageJson;

export const fetchReleaseNotes = async (targetVersion = version) => {
  try {
    const response = await fetch(`/CHANGELOG/v${targetVersion}.md`);
    
    if (!response.ok) {
      throw new Error(`Failed to fetch release notes for version ${targetVersion}`);
    }
    
    return await response.text();
  } catch (error) {
    console.error('Error fetching release notes:', error);
    return `# Release Notes for v${targetVersion}\n\nWelcome to the latest version of our application!`;
  }
};

export const getVersionChangeType = (oldVersion, newVersion) => {
  if (!oldVersion || !newVersion) return 'major';
  
  const oldParts = oldVersion.split('.').map(Number);
  const newParts = newVersion.split('.').map(Number);
  
  if (newParts[0] > oldParts[0]) return 'major';
  
  if (newParts[0] === oldParts[0] && newParts[1] > oldParts[1]) return 'minor';
  
  if (newParts[0] === oldParts[0] && newParts[1] === oldParts[1] && newParts[2] > oldParts[2]) return 'patch';
  
  return 'same';
};

export const hasUserSeenReleaseNotes = () => {
  if (typeof window === 'undefined') return false;
  
  const lastSeenVersion = localStorage.getItem('lastSeenReleaseNotes');
  
  if (lastSeenVersion === version) return true;
  
  const changeType = getVersionChangeType(lastSeenVersion, version);
  if (changeType === 'patch') {
    markReleaseNotesAsSeen();
    return true;
  }
  
  return false;
};

export const markReleaseNotesAsSeen = () => {
  if (typeof window === 'undefined') return;
  
  localStorage.setItem('lastSeenReleaseNotes', version);
};

export const getCurrentVersion = () => {
  return version;
}; 