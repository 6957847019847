import Link from "next/link";

import AccessControl from "@/components/permissions/AccessControl";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { cn } from "@/lib/utils";

const SidebarOptionMenu = ({
  option,
  selectedLinkClasses,
  selectedSubLinkClasses,
  subLinkClasses,
  linkClasses,
  currentSection,
}) => {
  const isCurrentSection = (section) => {
    if (section.handle == currentSection) return true;
    if (!section.subMenu) return false;

    return section.subMenu.find((sub) => sub.handle == currentSection);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div
          className={cn(
            isCurrentSection(option) ? selectedLinkClasses : linkClasses,
            "cursor-pointer"
          )}
        >
          <option.icon />
          <span>{option.name}</span>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="w-56 dark:bg-zinc-900 dark:border-zinc-700"
        side="right"
      >
        {option.subMenu.map((subOption, index) => (
          <AccessControl
            requiredPermissions={subOption.requiredPermissions}
            key={index}
          >
            <Link
              href={subOption.link}
              className="dark:text-blue-100"
            >
              <DropdownMenuItem
                className={`flex items-center gap-2 cursor-pointer w-full py-2 px-2 border-none text-sm
                ${
                  subOption.handle === currentSection
                    ? selectedSubLinkClasses
                    : subLinkClasses
                }`}
                key={index}
              >
                <subOption.icon size={18} />
                <span>{subOption.name}</span>
              </DropdownMenuItem>
            </Link>
          </AccessControl>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default SidebarOptionMenu;
