"use client";

import { useReactiveVar } from "@apollo/client";

import { ApolloWrapper } from "@/lib/apollo-provider";
import { useLocalStorageSession } from "@/lib/hooks";
import {
  currentSectionVar,
  sidebarCollapsedVar,
  sectionTitleVar,
} from "@/lib/local-state";
import { SectionTitleBar } from "@/components/Layout/SectionTitleBar";
import ReleaseNotesModal from "@/components/ReleaseNotesModal";

import Sidebar from "./Sidebar";
import "../styles/globals.scss";

export default function Layout({ children }) {
  const sidebarCollapsed = useReactiveVar(sidebarCollapsedVar);
  const currentSection = useReactiveVar(currentSectionVar);
  const sectionTitle = useReactiveVar(sectionTitleVar);
  useLocalStorageSession();

  const sectionPagesWithFullWidth = ["login", "verify-account"];
  const isAuthenticated = !sectionPagesWithFullWidth.includes(currentSection);

  return (
    <ApolloWrapper>
      {sectionPagesWithFullWidth.includes(currentSection) ? (
        children
      ) : (
        <main
          className={`w-full scroll-none text-gray-800 h-full fixed top-0 left-0 right-0 bottom-0`}
        >
          <Sidebar />

          <div
            className={`overflow-x-hidden absolute p-6 top-0 bottom-0 right-0 ${
              sidebarCollapsed ? "left-[110px]" : "left-[230px]"
            } transition-all ease-in-out duration-200`}
          >
            <div className="flex flex-col w-full">
              <SectionTitleBar
                title={sectionTitle?.title}
                headline={sectionTitle?.headline}
                icon={sectionTitle?.icon}
              />
              <>{children}</>
            </div>
          </div>
          
          {/* Solo mostrar el modal de release notes para usuarios autenticados */}
          {isAuthenticated && <ReleaseNotesModal />}
        </main>
      )}
    </ApolloWrapper>
  );
}
