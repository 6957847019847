import { useState, useEffect } from "react";

import { Info } from "lucide-react";

import ReleaseNotesModal from "@/components/ReleaseNotesModal";
import { getCurrentVersion } from "@/lib/services/releaseNotes";

const VersionInfo = ({ collapsed }) => {
  const [version, setVersion] = useState("0.0.0");
  const [showReleaseNotes, setShowReleaseNotes] = useState(false);

  useEffect(() => {
    const appVersion = getCurrentVersion();
    setVersion(appVersion);
  }, []);

  const handleVersionClick = () => {
    setShowReleaseNotes(true);
  };

  return (
    <>
      <div
        className={`flex items-center cursor-pointer text-xs text-slate-400 dark:text-zinc-500 hover:text-slate-600 dark:hover:text-zinc-400 transition-all duration-300 ease-in-out ${
          collapsed ? "justify-center" : "px-6 py-2"
        }`}
        onClick={handleVersionClick}
        title="View release notes"
      >
        <Info size={16} className={collapsed ? "mx-auto" : "mr-2"} />
        {!collapsed && <span>Version: {version}</span>}
      </div>

      {showReleaseNotes && (
        <ReleaseNotesModal
          isOpen={showReleaseNotes}
          onClose={() => setShowReleaseNotes(false)}
        />
      )}
    </>
  );
};

export default VersionInfo;
