import dynamic from "next/dynamic";

export const QuickViewRegistry = {
  // Brands
  brandDetails: {
    hasDeeplink: true,
    component: dynamic(() => import("@/app/brands/BrandDetails")),
    defaultProps: {
      size: "md",
      initialTitle: "Brand Details",
      initialSubtitle: "Loading details...",
    },
  },

  // Orders
  orderDetails: {
    hasDeeplink: true,
    component: dynamic(() => import("@/components/orders/order-details")),
    defaultProps: {
      size: "xxl",
      initialTitle: "Order Details",
      initialSubtitle: "Loading details...",
      xPadding: true,
    },
  },
  orderAdvancedFilters: {
    component: dynamic(() => import("@/components/orders/advanced-filters")),
    defaultProps: {
      size: "xs",
      initialTitle: "Order Filters",
      initialSubtitle: "Filter orders by status, date, and more.",
      xPadding: true,
    },
  },
  orderForm: {
    hasDeeplink: false,
    component: dynamic(() => import("@/components/orders/form/OrderForm")),
    defaultProps: {
      size: "md",
      initialTitle: "Edit Order",
      initialSubtitle: "Add a new order to your orders list",
      xPadding: true,
      requiresDiscard: true,
    },
  },
  markAsShipped: {
    hasDeeplink: true,
    component: dynamic(() => import("@/components/orders/mark-as-shipped/MarkAsShippedForm")),
    defaultProps: {
      size: "md",
      initialTitle: "Mark as Shipped",
      initialSubtitle: "Mark an order as shipped",
      xPadding: true,
      requiresDiscard: true,
    },
  },

  // Warehouses
  warehouseDetails: {
    hasDeeplink: true,
    component: dynamic(() => import("@/app/warehouses/WarehouseDetails")),
    defaultProps: {
      size: "md",
      initialTitle: "Warehouse Details",
      initialSubtitle: "Loading details...",
      xPadding: false,
    },
  },
  addLocation: {
    component: dynamic(() => import("@/components/warehouses/location-form")),
    defaultProps: {
      size: "sm",
      initialTitle: "Add Location",
      initialSubtitle: "Add a new location",
      xPadding: true,
    },
  },
  locationDetails: {
    hasDeeplink: true,
    component: dynamic(() =>
      import("@/components/warehouses/location-details")
    ),
    defaultProps: {
      size: "sm",
      initialTitle: "Location Details",
      initialSubtitle: "Loading details...",
      xPadding: false,
    },
  },
  channelDetails: {
    hasDeeplink: true,
    component: dynamic(() => import("@/components/channels/quickview/ChannelDetails")),
    defaultProps: {
      size: "sm",
      initialTitle: "Sales Channel Details",
      initialSubtitle: "Loading details...",
    },
  },

  // Inventory
  inventoryItemDetails: {
    hasDeeplink: true,
    component: dynamic(() => import("@/components/inventory/item-details")),
    defaultProps: {
      size: "xl",
      initialTitle: "Inventory Item Details",
      initialSubtitle: "Loading details...",
    },
  },
  inventoryAdvancedFilters: {
    component: dynamic(() => import("@/components/inventory/advanced-filters")),
    defaultProps: {
      size: "xs",
      initialTitle: "Inventory Filters",
      initialSubtitle: "Filter inventory by status, date, and more.",
      xPadding: true,
    },
  },

  // Product
  productAdvancedFilters: {
    component: dynamic(() => import("@/components/products/list/AdvancedFilters")),
    defaultProps: {
      size: "xs",
      initialTitle: "Product Filters",
      initialSubtitle: "Filter Product by status, keyword and more.",
      xPadding: true,
    },
  },
  productDetails: {
    hasDeeplink: true,
    component: dynamic(() => import("@/components/products/quickview/ProductDetails")),
    defaultProps: {
      size: "xl",
      initialTitle: "Product Details",
      initialSubtitle: "Product Details",
      xPadding: true,
    },
  },
  productForm: {
    hasDeeplink: false,
    component: dynamic(() => import("@/components/products/form/ProductForm")),
    defaultProps: {
      size: "md",
      initialTitle: "Edit Product",
      initialSubtitle: "Edit Product to your catalog",
      xPadding: true,
      requiresDiscard: true,
    },
  },

  // ASN
  asnDetails: {
    hasDeeplink: true,
    component: dynamic(() => import("@/components/asn/asn-details")),
    defaultProps: {
      size: "xxl",
      initialTitle: "ASN Details",
      initialSubtitle: "Loading details...",
    },
  },
  asnAdvancedFilters: {
    component: dynamic(() =>
      import("@/components/asn/main-view/asn-advanced-filters")
    ),
    defaultProps: {
      size: "xs",
      initialTitle: "ASN Advanced Filters",
      initialSubtitle: "Filter ASN using advanced filters",
      xPadding: true,
    },
  },
  ASNForm: {
    hasDeeplink: false,
    component: dynamic(() => import("@/components/asn/form/ASNForm")),
    defaultProps: {
      size: "md",
      initialTitle: "Edit ASN",
      initialSubtitle: "Add a new ASN to your ASN list",
      xPadding: true,
      requiresDiscard: true,
    },
  },

  // Integrations
  integrationDetails: {
    hasDeeplink: true,
    component: dynamic(() =>
      import("@/components/integrations/integration-details")
    ),
    defaultProps: {
      size: "md",
      initialTitle: "Integration Details",
      initialSubtitle: "Loading details...",
    },
  },

  // Data Mapper
  dataMapperDetails: {
    hasDeeplink: true,
    component: dynamic(() => import("@/components/integrations/data-mapper/DataMapper")),
    defaultProps: {
      size: "lg",
      initialTitle: "Data Mapper",
      initialSubtitle: "Loading details...",
    },
  }, 
  
};
