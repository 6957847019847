import React from "react";

import { usePermission } from "@/hooks/usePermission";

const AccessControl = ({
  requiredPermissions = [],
  requiredRoles = [],
  fallback = null,
  children,
  relationType = "OR",
}) => {
  const { userPermissions, userRoles, systemAdmin } = usePermission();

  if (requiredPermissions.length === 0 && requiredRoles.length === 0) {
    return <>{children}</>;
  }

  const hasRequiredRole = () =>
    requiredRoles.length > 0 &&
    requiredRoles.some((role) => userRoles.includes(role));

  const hasRequiredPermission = () => {
    if (relationType === "AND") {
      return requiredPermissions.every((permission) =>
        userPermissions.includes(permission)
      );
    } 
    
    return requiredPermissions.some((permission) =>
      userPermissions.includes(permission)
    );
  };

  const hasAccess = hasRequiredRole() || hasRequiredPermission() || systemAdmin;

  if (!hasAccess) {
    return fallback;
  }

  return <>{children}</>;
};

export default AccessControl;
