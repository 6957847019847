import React from "react";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import BugsnagPerformance from "@bugsnag/browser-performance";

const BUGSNAG_API_KEY = process.env.NEXT_PUBLIC_BUGSNAG_API_KEY || "";

Bugsnag.start({
  apiKey: BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.NEXT_PUBLIC_CURRENT_ENV || "development",
  enabledReleaseStages: ["production", "staging", "development"],
});

BugsnagPerformance.start({
  apiKey: BUGSNAG_API_KEY,
});

export const ErrorBoundary =
  Bugsnag.getPlugin("react").createErrorBoundary(React);

export default Bugsnag;