export const PERMISSIONS = {
  ADD_COLOR: "add_color",
  ADD_FULFILLMENT_METADATA: "add_fulfillment_metadata",
  ADD_NOTE: "add_note",
  ADD_ORDER_ITEMS: "add_order_items",
  ADD_PRODUCT: "add_product",
  ADD_PRODUCT_CATEGORY: "add_product_category",
  ADD_PRODUCT_OPTION: "add_product_option",
  ADD_RELATIONSHIP: "add_relationship",
  ADD_ROLE_TYPE: "add_role_type",
  ADD_SIZE: "add_size",
  ADD_STYLE: "add_style",
  ADJUST_INVENTORY_ITEM: "adjust_inventory_item",
  ALLOCATE_INVENTORY: "allocate_inventory",
  ALLOCATE_ORDER: "allocate_order",
  ASSIGN_COMPANY_ROLE: "assign_company_role",
  ASSIGN_LOB_ROLE: "assign_lob_role",
  ASSIGN_SYSTEM_ROLE: "assign_system_role",
  CANCEL_ORDER: "cancel_order",
  CHANGE_ORDER_STATUS: "change_order_status",
  CLONE_PRODUCT: "clone_product",
  COMMIT_ALLOCATION: "commit_allocation",
  CREATE_LOCATION: "create_location",
  CREATE_ORDER: "create_order",
  CREATE_SALES_CHANNEL: "create_sales_channel",
  FULFILL_ORDER: "fulfill_order",
  IMPORT_PRODUCT: "import_product",
  INIT_INVENTORY_TRANSFER: "init_inventory_transfer",
  INITIALIZE_BRAND_INVENTORY: "initialize_brand_inventory",
  INITIALIZE_INVENTORY: "initialize_inventory",
  INVITE_PERSON: "invite_person",
  ONBOARD_BRAND: "onboard_brand",
  PRE_ALLOCATE_ORDER: "pre_allocate_order",
  REGISTER_CARRIER_MAPPINGS: "register_carrier_mappings",
  REGISTER_CARRIER_SERVICES: "register_carrier_services",
  REGISTER_CARRIERS: "register_carriers",
  REGISTER_COMPANY: "register_company",
  REGISTER_GLOBAL_CARRIER_MAPPINGS: "register_global_carrier_mappings",
  REGISTER_GLOBAL_CARRIERS: "register_global_carriers",
  REGISTER_GLOBAL_SERVICE_MAPPINGS: "register_global_service_mappings",
  REGISTER_SERVICE_MAPPINGS: "register_service_mappings",
  REGISTER_WAREHOUSE: "register_warehouse",
  REMOVE_COMPANY_ROLE: "remove_company_role",
  REMOVE_INTEGRATION: "remove_integration",
  REMOVE_LOB_ROLE: "remove_lob_role",
  REMOVE_NOTES: "remove_notes",
  REMOVE_ORDER_ITEM: "remove_order_item",
  REMOVE_RELATIONSHIP: "remove_relationship",
  REMOVE_SYSTEM_ROLE: "remove_system_role",
  RUN_INTEGRATION_ACTION: "run_integration_action",
  SET_FULFILLMENT_INTEGRATION: "set_fulfillment_integration",
  SET_PRODUCT_EXTERNAL_DATA: "set_product_external_data",
  SETUP_INTEGRATION: "setup_integration",
  SHIP_ORDER: "ship_order",
  SYNC_PRODUCTS: "sync_products",
  UNCOMMIT_ALLOCATION: "uncommit_allocation",
  UNFULFILL_ORDER: "unfulfill_order",
  UNSHIP_ORDER: "unship_order",
  UPDATE_BRAND: "update_brand",
  UPDATE_INTEGRATION: "update_integration",
  UPDATE_MY_PROFILE: "update_my_profile",
  UPDATE_ORDER: "update_order",
  UPDATE_ORDER_ITEM: "update_order_item",
  UPDATE_PRODUCT: "update_product",
  UPDATE_SALES_CHANNEL: "update_sales_channel",
  UPDATE_WAREHOUSE: "update_warehouse",
  REGISTER_GLOBAL_CARRIER_SERVICES: "register_global_carrier_services",
  REGISTER_API_CLIENT: "register_api_client",
  CREATE_API_KEY: "create_api_key",
  ADD_EXTERNAL_RELATION: "add_external_relation",
  UPDATE_API_CLIENT: "update_api_client",
  LINK_EXTERNAL_RELATIONS: "link_external_relations",
  HOLD_ORDER: "hold_order",
  RELEASE_ORDER: "release_order",
  CREATE_ASN: "create_asn",
  MOVE_ASN_FORWARD: "move_asn_forward",
  GET_EXTERNAL_SHIPMENT: "get_external_shipment",
  MARK_ASN_ARRIVED: "mark_asn_arrived",
  START_ASN_RECEIVING: "start_asn_receiving",
  ASN_UPDATE_RECEIVING_ITEM: "asn.update_receiving_item",
  ASN_ADD_RECEIVING_ITEM: "asn:add_receiving_item",
  ADD_SHIPPING_CARRIER_SERVICE: "add_shipping_carrier_service",
  IMPORT_SHIPPING_CARRIER_SERVICE: "import_shipping_carrier_service",
  EDIT_LINKED_EXTERNAL_RELATIONS: "edit_linked_external_relations",
  ASN_CLOSE: "asn:close",
  ASN_SEND_TO_WMS: "asn:send_to_wms",
  ASN_CANCEL: "asn:cancel",
  ASN_SYNC_INVENTORY: "asn:sync_inventory",
  ASN_CHANGE_STATUS: "asn:change_status",
  GET_ALLOWED_ROLES: "get_allowed_roles",
  GET_CATEGORY: "get_category",
  GET_COLORS: "get_colors",
  GET_COMPANY_DETAILS: "get_company_details",
  GET_INTEGRATION_ACTIONS: "get_integration_actions",
  GET_INTEGRATION_DETAILS: "get_integration_details",
  GET_INTEGRATION_TYPES: "get_integration_types",
  GET_ORDERS: "get_orders",
  GET_PARTY_DETAILS: "get_party_details",
  GET_PRODUCT_DETAILS: "get_product_details",
  GET_PRODUCTS: "get_products",
  GET_RELATIONSHIPS: "get_relationships",
  GET_ROLE_TYPES: "get_role_types",
  GET_SALES_CHANNELS: "get_sales_channels",
  GET_SIZES: "get_sizes",
  GET_STYLES: "get_styles",
  GET_WAREHOUSE_DETAILS: "get_warehouse_details",
  LIST_BRAND_PERSONS: "list_brand_persons",
  LIST_BRANDS: "list_brands",
  LIST_CATEGORIES: "list_categories",
  LIST_COMPANY_PERSONS: "list_company_persons",
  LIST_INTEGRATIONS: "list_integrations",
  LIST_INVENTORY: "list_inventory",
  LIST_LOCATIONS: "list_locations",
  LIST_PERSONS: "list_persons",
  LIST_WAREHOUSES: "list_warehouses",
  LIST_ZONES: "list_zones",
  READ_BRAND: "read_brand",
  READ_CARRIER_SERVICES: "read_carrier_services",
  READ_INVENTORY_ITEM: "read_inventory_item",
  READ_NOTES: "read_notes",
  READ_ORDER: "read_order",
  READ_ORDER_ITEMS: "read_order_items",
  READ_ORDER_METADATA: "read_order_metadata",
  READ_ORDER_NOTES: "read_order_notes",
  READ_SALES_CHANNEL: "read_sales_channel",
  READ_SYSTEM_LOGS: "read_system_logs",
  LIST_CARRIERS: "list_carriers",
  READ_API_CLIENTS: "read_api_clients",
  READ_API_CLIENT: "read_api_client",
  READ_API_KEY: "read_api_key",
  READ_ASNS: "read_asns",
  READ_ASN: "read_asn",
  GET_EXTERNAL_RELATIONS: "get_external_relations",
  ASN_READ_AVAILABLE_ITEMS: "asn:read_available_items",
  LIST_SHIPPING_CARRIER_SERVICES: "list_shipping_carrier_services",
  LIST_PRODUCTS_EXTERNAL: "list_products_external",
  LIST_WAREHOUSES_EXTERNAL: "list_warehouses_external",
  LIST_SALES_CHANNELS_EXTERNAL: "list_sales_channels_external",
  CUSTOMERS_READ_CUSTOMERS: "customers:read_customers",
  CUSTOMERS_READ_CUSTOMER: "customers:read_customer",
  BRAND_ORDER_UPDATES: "brand_order_updates",
  COMPANY_ORDER_UPDATES: "company_order_updates",
  ORDER_UPDATES: "order_updates",
  WAREHOUSE_UPDATED: "warehouse_updated",
  BRAND_ASN_UPDATES: "brand_asn_updates",
  COMPANY_ASN_UPDATES: "company_asn_updates",
  ASN_UPDATES: "asn_updates",
  ORDERS_IMPORT_LINE_ITEM: "orders:import_line_item",
  ORDERS_REFRESH_ORDER: "orders:refresh_order",
  MAPPER_READ_MAPPINGS: "mapper:read_mappings",
  INVENTORY_BATCH_ADJUST: "inventory:batch_adjust",
  READ_FEATURE_FLAGS: "read_feature_flags",
  ORDERS_UNCANCEL_ORDER: "orders:uncancel_order",
  GLOBAL_READ_ACCOUNT_FEATURES: "global:read_account_features",
};

