import { useReactiveVar } from "@apollo/client";

import { useQuickView } from "@/hooks/useQuickView";
import { quickViewStackVar } from "@/lib/local-state";
import { QuickViewRegistry } from "@/lib/quickview-registry";

import QuickView from "./QuickView";

export default function QuickViewManager() {
  const stack = useReactiveVar(quickViewStackVar);
  const { close } = useQuickView();

  if (stack.length === 0) return null;

  return (
    <>
      {stack.map((view, index) => {
        const registration = QuickViewRegistry[view.type];
        if (!registration) return null;

        const { component: Component } = registration;

        return (
          <QuickView
            key={`${view.type}-${index}`}
            open={view.props.isVisible}
            onOpenChange={(isOpen) => {
              if (!isOpen) close(index);
            }}
            onCloseComplete={() => {
              if (view.props.removing) {
                const currentStack = quickViewStackVar();
                const finalStack = currentStack.filter((_, i) => i !== index);

                if (finalStack.length > 0) {
                  const updatedStack = finalStack.map((item, i) => ({
                    ...item,
                    props: {
                      ...item.props,
                      isVisible: i === finalStack.length - 1,
                    },
                  }));
                  quickViewStackVar(updatedStack);
                } else {
                  quickViewStackVar([]);
                }
              }
            }}
            {...view.props}
          >
            <Component {...view.props} />
          </QuickView>
        );
      })}
    </>
  );
}
