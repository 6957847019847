"use client";

import "@/styles/globals.scss";

import { useEffect } from "react";

import { useReactiveVar } from "@apollo/client";
import { Fira_Sans, Fira_Code } from "next/font/google";
import { AppProgressBar as ProgressBar } from 'next-nprogress-bar';
import { toast } from "sonner";

import Layout from "@/components/Layout";
import DeeplinkHandler from "@/components/quickview/DeeplinkHandler";
import QuickViewManager from "@/components/QuickViewManager";
import { Toaster } from "@/components/ui/sonner";
import { ErrorBoundary } from "@/lib/bugsnag";
import HotjarLoader from "@/lib/hotjar";
import { errorVar, userDataVar } from "@/lib/local-state";
import { cn } from "@/lib/utils";

const fira = Fira_Sans({
  subsets: ["latin"],
  variable: "--font-sans",
  weight: ["400", "500", "600", "700"],
});

const firaCode = Fira_Code({
  subsets: ["latin"],
  variable: "--font-mono",
  weight: ["400", "500", "700"],
});


export default function RootLayout({ children }) {
  const error = useReactiveVar(errorVar);
  const userData = useReactiveVar(userDataVar);
  const theme = userData?.theme;

  useEffect(() => {
    errorVar(null);
  }, []);

  useEffect(() => {
    if (error) {
      toast.error("Error", {
        description: error.message,
      });
    }
  }, [error]);

  return (
    <html
      lang="en"
      className={`${fira.variable} ${firaCode.variable} ${
        theme === "dark" ? "dark" : ""
      }`}
    >
      <head>
        <title>Endless Commerce</title>
        <link rel="icon" href="/favicon.svg" sizes="any" />
        <HotjarLoader />
      </head>
      <body
        className={cn(
          "bg-gray-100 dark:bg-zinc-900 h-full font-sans antialiased"
        )}
      >
        <ErrorBoundary>
          <Layout>
            <ProgressBar
              height="2px"
              color="#1d4ed8"
              options={{ showSpinner: false }}
              shallowRouting
            />
            {children}
            <QuickViewManager />
            <DeeplinkHandler />
          </Layout>
          <Toaster richColors />
        </ErrorBoundary>
      </body>
    </html>
  );
}
