import { useEffect, useState } from "react";

import { useReactiveVar } from "@apollo/client";
import {
  Users,
  Building2,
  Award,
  KeySquare,
  Store,
  Flag,
  TicketCheck,
  MapPin,
  Component,
  Tag,
  Blocks,
  Settings,
  ClipboardList,
  Archive,
  ShoppingBag,
} from "lucide-react";
import Image from "next/image";
import Link from "next/link";

import AccessControl from "@/components/permissions/AccessControl";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { PERMISSIONS } from "@/constants/permissions";
import { useSession } from "@/lib/hooks";
import { currentSectionVar, sidebarCollapsedVar, userDataVar } from "@/lib/local-state";

import SidebarOptionAccordion from "./parts/sidebar-option-accordion";
import SidebarOptionMenu from "./parts/sidebar-option-menu";
import VersionInfo from "./parts/VersionInfo";

const LocalTooltip = ({ collapsed, children, caption }) => {
  return collapsed ? (
    <TooltipProvider>
      <Tooltip delayDuration={500}>
        <TooltipTrigger asChild>{children}</TooltipTrigger>
        <TooltipContent
          side="right"
          className="dark:bg-zinc-900 dark:text-zinc-400 dark:border dark:border-zinc-700"
        >
          <p>{caption}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  ) : (
    children
  );
};

const sidebarOptions = [
  /*{
    name: "Dashboard",
    handle: "dashboard",
    icon: CircleGauge,
    requiredPermissions: [],
    link: "/",
  },*/
  {
    name: "Orders",
    handle: "orders",
    icon: ShoppingBag,
    requiredPermissions: [PERMISSIONS.GET_ORDERS],
    link: "/orders",
  },
  {
    name: "Inventory",
    handle: "inventory",
    icon: Archive,
    requiredPermissions: [PERMISSIONS.LIST_INVENTORY],
    link: "/inventory",
  },
  {
    name: "Products",
    handle: "products",
    icon: Tag,
    requiredPermissions: [PERMISSIONS.GET_PRODUCTS],
    link: "/products",
  },
  {
    name: "ASN",
    handle: "asn",
    icon: ClipboardList,
    requiredPermissions: [PERMISSIONS.READ_ASN],
    link: "/asn",
  },
  {
    name: "Buildings",
    handle: "warehouses",
    icon: MapPin,
    requiredPermissions: [PERMISSIONS.LIST_WAREHOUSES],
    link: "/warehouses",
  },
  {
    name: "Modules",
    handle: "modules",
    icon: Component,
    link: "/modules",
    subMenu: [
      {
        name: "People",
        handle: "team",
        icon: Users,
        requiredPermissions: [PERMISSIONS.LIST_PERSONS],
        link: "/people",
      },
      {
        name: "Companies",
        handle: "companies",
        icon: Building2,
        requiredPermissions: [PERMISSIONS.LIST_COMPANY_PERSONS],
        link: "/companies",
      },
      {
        name: "Brands",
        handle: "brands",
        icon: Award,
        requiredPermissions: [PERMISSIONS.LIST_BRANDS],
        link: "/brands",
      },
      {
        name: "Integrations",
        handle: "integrations",
        icon: Blocks,
        requiredPermissions: [PERMISSIONS.LIST_INTEGRATIONS],
        link: "/integrations",
      },
      {
        name: "Sales Channels",
        handle: "channels",
        icon: Store,
        requiredPermissions: [PERMISSIONS.READ_SALES_CHANNEL],
        link: "/channels",
      },
    ],
  },
];

const systemMenu = {
  name: "System",
  handle: "system",
  icon: Settings,
  link: "/system",
  requiredPermissions: [
    PERMISSIONS.READ_API_CLIENT,
    PERMISSIONS.READ_SYSTEM_LOGS,
  ],
  subMenu: [
    {
      name: "API Clients",
      handle: "api-clients",
      icon: KeySquare,
      requiredPermissions: [PERMISSIONS.READ_API_CLIENT],
      link: "/api-clients",
    },
    {
      name: "Feature Flags",
      handle: "feature-flags",
      icon: Flag,
      requiredPermissions: [],
      link: "/flags",
    },
    {
      name: "Events",
      handle: "events",
      link: "/system/events",
      requiredPermissions: [PERMISSIONS.READ_SYSTEM_LOGS],
      icon: TicketCheck,
    },
  ],
};

const Sidebar = () => {
  const currentSection = useReactiveVar(currentSectionVar);
  const [collapsed, setCollapsed] = useState(false);
  const baseClasses = `flex items-center text-md py-4 px-6 ${
    collapsed
      ? "justify-center [&>_span]:hidden [&>_svg]:mr-0 [&>_svg]:w-[24px]"
      : "[&>_svg]:w-[20px] [&>_svg]:mr-3"
  }`;
  const linkClasses =
    baseClasses +
    " border-none text-slate-400 dark:text-zinc-500 hover:text-slate-400 dark:hover:text-zinc-400 hover:bg-slate-100 dark:hover:bg-zinc-700/30";
  const selectedLinkClasses = baseClasses + " border-0 text-white bg-blue-700 dark:bg-zinc-900";
  const subLinkClasses =
    "text-slate-400 hover:text-slate-600 hover:bg-slate-100 dark:text-zinc-500 dark:hover:text-zinc-400";
  const selectedSubLinkClasses =
    "text-blue-600 [&>_svg]:stroke-blue-600 dark:text-white dark:[&>_svg]:stroke-white ";
  const sidebarCollapsed = useReactiveVar(sidebarCollapsedVar);
  const { sessionData, loading } = useSession("mvSession");
  const userSession = useReactiveVar(userDataVar);

  useEffect(() => {
    if (!loading) {
      setCollapsed(sessionData?.sidebarCollapsed || false);
      sidebarCollapsedVar(sessionData?.sidebarCollapsed || false);
    }
  }, [loading, sessionData?.sidebarCollapsed]);

  useEffect(() => {
    if (sidebarOptions.find((option) => option.handle === "system")) {
      return;
    }

    if (userSession?.systemAdmin) {
      sidebarOptions.push(systemMenu);
    }
  }, [userSession]);

  useEffect(() => {
    setCollapsed(sidebarCollapsed);
  }, [sidebarCollapsed]);

  return (
    <div
      className={`app-sidebar fixed overflow-x-hidden top-6 left-6 bottom-6 bg-white dark:bg-zinc-800 rounded-xl z-50 ${
        collapsed ? "w-[90px]" : "w-[200px]"
      } duration-200 ease-in-out`}
    >
      <div
        className={`flex flex-col h-full sidebar text-slate-500 dark:text-slate-800 transition-all duration-300 ease-in-out `}
      >
        <div className="border-b border-slate-100 dark:border-zinc-700 py-8 px-4 ">
          {collapsed ? (
            <Image src="/images/logo.svg" width={50} height={50} className="mx-auto" alt="MvnifestAI" />
          ) : (
            <Image src="/images/logo-h.svg" width={150} height={20} className="mx-auto" alt="MvnifestAI" />
          )}
        </div>
        <div className="flex-1">
          <ul className="[&>_li]:border-b [&>_li]:border-slate-100 [&>_li]:dark:border-zinc-700 text-sm">
            {sidebarOptions.map((option, index) => (
              <AccessControl requiredPermissions={option.requiredPermissions} key={index}>
                <li>
                  {collapsed ? (
                    <>
                      {option.subMenu ? (
                        <SidebarOptionMenu
                          option={option}
                          currentSection={currentSection}
                          selectedLinkClasses={selectedLinkClasses}
                          selectedSubLinkClasses={selectedSubLinkClasses}
                          linkClasses={linkClasses}
                          subLinkClasses={subLinkClasses}
                        />
                      ) : (
                        <LocalTooltip caption={option.name} collapsed={collapsed}>
                          <Link
                            href={option.link}
                            className={option.handle === currentSection ? selectedLinkClasses : linkClasses}
                          >
                            <option.icon />
                            <span>{option.name}</span>
                          </Link>
                        </LocalTooltip>
                      )}
                    </>
                  ) : (
                    <>
                      {option.subMenu ? (
                        <SidebarOptionAccordion
                          option={option}
                          currentSection={currentSection}
                          selectedLinkClasses={selectedLinkClasses}
                          selectedSubLinkClasses={selectedSubLinkClasses}
                          linkClasses={linkClasses}
                          subLinkClasses={subLinkClasses}
                        />
                      ) : (
                        <Link
                          href={option.link}
                          className={option.handle === currentSection ? selectedLinkClasses : linkClasses}
                        >
                          <option.icon />
                          <span>{option.name}</span>
                        </Link>
                      )}
                    </>
                  )}
                </li>
              </AccessControl>
            ))}
          </ul>
        </div>
        
        <div className="mt-auto border-t border-slate-100 dark:border-zinc-700 py-2">
          <VersionInfo collapsed={collapsed} />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
