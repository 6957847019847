"use client";

import { ApolloLink, HttpLink, split } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { getMainDefinition } from "@apollo/client/utilities";
import {
  ApolloNextAppProvider,
  NextSSRInMemoryCache,
  NextSSRApolloClient,
  SSRMultipartLink,
} from "@apollo/experimental-nextjs-app-support/ssr";
import { createConsumer } from "@rails/actioncable";
import ActionCableLink from "graphql-ruby-client/subscriptions/ActionCableLink";
import { useRouter } from "next/navigation";

import { useSessionValidation } from "@/lib/hooks";
import Bugsnag from "@/lib/bugsnag";
import { errorVar, userDataVar } from "./local-state";

const constructHeaders = () => {
  const userSession = userDataVar();
  const token = userSession ? userSession.token : null;
  const companyId = userSession ? userSession.companyId : null;
  const brandId = userSession ? userSession.brandId : null;

  return {
    authorization: token ? `Bearer ${token}` : "",
    "X-Company-Id": companyId ? companyId : "",
    "X-Brand-Id": brandId ? brandId : "",
    "X-EC-Access-Token": process.env.NEXT_PUBLIC_EC_ACCESS_TOKEN,
  };
};

const getSubscriptionsUri = () => {
  const uri = process.env.NEXT_PUBLIC_SUBSCRIPTIONS_URI;
  return uri
    .replace("http", "ws")
    .replace("https", "wss")
    .replace("graphql", "cable");
};

function makeClient() {
  let cable;

  if (typeof document !== "undefined") {
    cable = createConsumer(getSubscriptionsUri());
  }

  const httpLink = new HttpLink({
    uri: process.env.NEXT_PUBLIC_SERVER_URI,
  });

  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(() => ({
      headers: constructHeaders(),
    }));

    return forward(operation);
  });

  const actionCableLink = new ActionCableLink({
    cable,
    connectionParams: () => {
      const headers = constructHeaders();
      return headers;
    },
  });

  const errorLink = onError(({ graphQLErrors, networkError, operation, response }) => {
    const userSession = userDataVar();

    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        Bugsnag?.notify(new Error(error.message), (event) => {
          event.context = {
            query: operation?.query,
            variables: operation?.variables,
          };
          event.addMetadata("usersession", userSession);
          event.addMetadata("graphql", {
            query: operation?.query,
            variables: operation?.variables,
            response: response,
          });
          event.setUser(
            userSession?.partyId,
            userSession?.email,
            userSession?.firstName
          );
        });

        switch (error.extensions?.name) {
          case "LoginFailed":
            errorVar({
              message: error.message,
            });
            break;
          case "ErrorLoginRequired":
            if (typeof window !== "undefined") {
              errorVar({
                message: error.message,
              });
              localStorage.removeItem("mvSession");
              window.location.href = "/login";
            }
            break;
          default:
            if (typeof window !== "undefined") {
              errorVar({
                message: error.message,
              });
            }
        }
      });
    }

    if (networkError) {
      // Do we need to handle network errors?
    }
  });

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    actionCableLink,
    authLink.concat(httpLink)
  );

  const apolloLink =
    typeof window === "undefined"
      ? ApolloLink.from([
          new SSRMultipartLink({
            stripDefer: true,
          }),
          errorLink,
          link,
        ])
      : ApolloLink.from([errorLink, link]);

  return new NextSSRApolloClient({
    cache: new NextSSRInMemoryCache(),
    link: apolloLink,
  });
}

export function ApolloWrapper({ children }) {
  const router = useRouter();

  useSessionValidation({ router });

  return (
    <ApolloNextAppProvider makeClient={makeClient}>
      {children}
    </ApolloNextAppProvider>
  );
}
